h1 {

}

h1 span.title-secu {
    color: #0273ff;
}

h1 span.title-box {
    color: #ffa500;
}

h2 {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.mb40 {
    margin-bottom: 40px;
}

.error-message {
    margin-left: 20px;
    color: red;
    display: inline-block;
}

.result-message {
    margin-left: 20px;
    color: dodgerblue;
    display: inline-block;
}

.vault-number-title {
    font-size: 0.9em;
    line-height: 36px;
}

.vault-number-digit {
    width: 36px;
    height: 36px;
    margin-right: 20px;
    font-size: 1.2em;
    text-align: center;
    display: inline-block;
}

.vault-number-digit.first {
    margin-left: 0;
}

.vault-number-digit.last {
    margin-right: 0;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (min-width: 768px) {
    .vault-number-title {
        font-size: 0.9em;
        line-height: 36px;
    }

    .vault-number-digit {
        width: 35px;
        height: 35px;
        margin-right: 6px;
        font-size: 1.1em;
    }

    .vault-number-digit.first {
        margin-left: 0;
    }
}

@media (min-width: 992px) {
    .vault-number-title {
        font-size: 0.9em;
        line-height: 36px;
    }

    .vault-number-digit {
        width: 36px;
        height: 36px;
        margin-right: 20px;
        font-size: 1.2em;
    }

    .vault-number-digit.first {
        margin-left: 14px;
    }
}


@media (min-width: 1200px) {
    .vault-number-title {
        font-size: 1.1em;
        line-height: 40px;
    }

    .vault-number-digit {
        width: 40px;
        height: 40px;
        margin-right: 30px;
        font-size: 1.4em;
    }

    .vault-number-digit.first {
        margin-left: 7px;
    }
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}